
import http from './api';

class PedidoService{
    async getAll(){
        return http.get('/api/v1/pedido/list-all').then((result)=>{
            return result;
        }).catch((error)=>{
            throw error;
        })
    }

    async getByPedido(pedidoId){   
        return http.get(`/api/v1/pedido/list-order/${pedidoId}`).then((result) => {
            return (result)
        }).catch((error) => {
            return (error)
        })
    }

    
}

const pedidoService = new PedidoService()
export default pedidoService