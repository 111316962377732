import React, { useState,useEffect } from 'react';
import Logo from "../../img/logo.png"
import Perfil from "../../img/p2.png"
import './style.css'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'; 
import { Link } from 'react-router-dom';
import http from '../../services/api';
import jsPDF from 'jspdf';
import certidaoService from '../../services/CertidaoService';
import pedidoService from '../../services/PedidoService';
import { useLocation } from 'react-router-dom';



import QRious from 'qrious';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function MostrarPdf() {

  function formatarData(data){
    const dataObj =new Date(data);
    return dataObj.toLocaleDateString("pt-BR",{day:"2-digit",month:"long",year:"numeric"})
  }
  
  


  const[pedidoId,setPedidoId]=useState(1);
  const[certidaoId,setCertidaoId]=useState(1);    

  const[pedido,setPedido]=useState();

  
  const query = useQuery();
  
  useEffect(()=>{
    const pedidoId=query.get('pedidoId');
    const certidaoId=query.get('certidaoId');

    if(pedidoId) setPedidoId(pedidoId);
    if(certidaoId) setCertidaoId(certidaoId);

  },[query])

  const[certidao,setCertidao]=useState();  
  useEffect(() => {
    const fetchData = async () => {
      try {
          certidaoService.getByCertidao(certidaoId).then((response)=>{
            console.log(response.data.data)
            setCertidao(response.data.data);
          });

          pedidoService.getByPedido(pedidoId).then((res)=>{
            setPedido(res.data.data)
          });


      } catch (error) {
        console.log(error)
      }
    };
    fetchData();
  }, []);



  const [nome_mae, setnome_mae] = useState('');
  const [nome_pai, setnome_pai] = useState('');
  const [data_nascimento, setdata_nascimento] = useState('');
  const [data_batismo, setdata_batismo] = useState('');
  const [celebrante, setCelebrante] = useState('');
  const [batizando, setBatizando] = useState('');
  const [livro, setLivro] = useState('');
  const [folha, setFolha] = useState('');
  const [numero, setNumero] = useState('');
  const [paroco, setParoco] = useState('');
  const [paroquiaId, setParoquiaId] = useState(1);

  const [paroquia, setParoquia] = useState('');

  const [nomeParoquia, setNomeParoquia] = useState();
  

 useEffect(()=>{
  if(certidao){
    setnome_mae(certidao.nome_mae)
    setnome_pai(certidao.nome_pai) 
    setdata_batismo(certidao.data_batismo)
    setdata_nascimento(certidao.data_nascimento)
    setCelebrante(certidao.celebrante)
    setBatizando(certidao.batizando)
    setLivro(certidao.livro)
    setFolha(certidao.folha)
    setNumero(certidao.numero)
    setParoco(certidao.paroco)
    setNomeParoquia(certidao.paroquia.nome)
    setParoquia(certidao.paroquia)
  }

},[certidao])

  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfBlob,setPdfBlob]=useState(null);

  const generatePDF = () => {
    const doc = new jsPDF();

 
    doc.setFontSize(12);
    doc.setFont("Times", "bold");
    doc.text("ARQUIDIOCESE DE APARECIDA", 105, 20, { align: "center" });
    doc.text(nomeParoquia, 105, 27, { align: "center" });

    // Informações de contato
    doc.setFont("Times", "normal");
    doc.setFontSize(10);
    doc.text("CNPJ: 53.329.421/0023-32", 105, 34, { align: "center" });
    doc.text("E-mail: nsrosario@arqaparecida.org.br", 105, 40, { align: "center" });
    doc.text("Fone / Fax: (12) 3132-3623", 105, 46, { align: "center" });

    // Título do documento
    doc.setFontSize(18);
    doc.setFont("Times", "italic");
    doc.text("Certidão de Batismo", 105, 60, { align: "center" });

    // Dados principais
    doc.setFontSize(12);
    doc.setFont("Times", "bold");
    doc.text("Data da celebração:", 20, 80);
    doc.setFont("Times", "normal");
    doc.text(formatarData(data_batismo), 70, 80);

    doc.setFont("Times", "bold");
    doc.text("Local:", 20, 90);
    doc.setFont("Times", "normal");
    doc.text(`${nomeParoquia}`, 70, 90);

    doc.setFont("Times", "bold");
    doc.text("Celebrante:", 20, 100);
    doc.setFont("Times", "normal");
    doc.text(`${celebrante}`, 70, 100);

    doc.setFont("Times", "bold");
    doc.text("Batizando:", 20, 110);
    doc.setFont("Times", "normal");
    doc.text(`${batizando}`, 70, 110);

    doc.setFont("Times", "bold");
    doc.text("Data de nascimento:", 20, 120);
    doc.setFont("Times", "normal");
    doc.text(formatarData(data_nascimento), 70, 120);

    doc.setFont("Times", "bold");
    doc.text("Nome do pai:", 20, 130);
    doc.setFont("Times", "normal");
    doc.text(nome_pai, 70, 130);

    doc.setFont("Times", "bold");
    doc.text("Nome da mãe:", 20, 140);
    doc.setFont("Times", "normal");
    doc.text(nome_mae, 70, 140);
  /*
    doc.setFont("Times", "bold");
    doc.text("Padrinhos:", 20, 150);
    doc.setFont("Times", "normal");
    doc.text("Milton César Chaves", 70, 150);
    doc.text("Geralda Brandão S. Chaves", 70, 160);*/

    // Informações de livro, folha e número
    doc.setFont("Times", "bold");
    doc.text("Livro:", 20, 180);
    doc.setFont("Times", "normal");
    doc.text(`${livro}`, 35, 180);

    doc.setFont("Times", "bold");
    doc.text("Folha:", 50, 180);
    doc.setFont("Times", "normal");
    doc.text(`${folha}`, 65, 180);

    doc.setFont("Times", "bold");
    doc.text("Número:", 80, 180);
    doc.setFont("Times", "normal");
    doc.text(`${numero}`, 100, 180);

    // Declaração final
    doc.setFontSize(10);
    doc.text("Para constar mandei lavrar esta certidão que assino.", 20, 200);
    doc.text('"Ita in fide párochi".', 20, 210);

    // Data e assinatura
    doc.text("GUARATINGUETÁ, 21 de junho de 2024.", 105, 230, { align: "center" });
    doc.setFont("Times", "italic");
    doc.text(`${paroco}`, 105, 250, { align: "center" });
    doc.text("Pároco", 105, 256, { align: "center" });
      // Converte o PDF para Blob e cria um URL temporário
      const pdfBlob = doc.output('blob');
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);
     
     // Define o URL do Blob para exibir no iframe
     setPdfUrl(pdfBlobUrl);
     setPdfBlob(pdfBlob);
  };


  const enviarEmail =async()=>{
    if(!pdfBlob){
      toast.error("primeiro gere o pdf")
      return '';
    }
    
    const formData = new FormData();
    formData.append('pdf',pdfBlob,'certidao.pdf')


  try {
    const response = await certidaoService.sendEmail(formData,certidaoId,pedidoId);
    toast.success("E-mail enviado com sucesso!");
    console.log(response.data.data)
    return response;
  } catch (error) {
    toast.error("Erro ao enviar o e-mail");
    console.log(error)
    return error;
  }
   
  }
  
  return (
    <div className='fazer-solicitacao'>
      <div className="container-fluid">
        <ToastContainer />

        <nav className="navbar">
          <div className="logo">
            <img src={Logo} alt="Certidões Católicas" />
          </div>
          <div className="user-actions">
            <Link to="/Login" style={{ border: 'none' }}>
              <img src={Perfil} alt="Acesso Restrito"  />
            </Link>
          </div>
        </nav>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card mt-4">
              <div className="card-header">
                <h3>Certidão de Batismo</h3>
              </div>
              <div className="card-body">
               
               <button onClick={generatePDF} className='btn btn-success'>Baixar PDF</button>
               <button onClick={enviarEmail} className='btn btn-primary ml-2'>Enviar por Email</button>
               {/* Renderiza o PDF no iframe se o URL do PDF existir */}
                {pdfUrl && (
                  <iframe
                    src={pdfUrl}
                    width="100%"
                    height="600px"
                    title="Visualização de PDF"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MostrarPdf;
