
import http from './api';

class CertidaoService{
    async getAll(){
        return http.get('/api/v1/certidao/list-all').then((result)=>{
            return result;
        }).catch((error)=>{
            throw error;
        })
    }

    async getByCertidao(certidaoId){   
        return http.get(`/api/v1/certidao/list/${certidaoId}`).then((result) => {
            return (result)
        }).catch((error) => {
            return (error)
        })
    }

    
    async sendEmail(formData,certidaoId,pedidoId){
        
        const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }

          return http.post(`/api/v1/certidao/send-email/${certidaoId}/${pedidoId}`,formData,config).then((response)=>{
            return (response)
          }).catch((error)=>{
            return error
          });
    }

}

const certidaoService = new CertidaoService()
export default certidaoService