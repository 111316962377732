import React, { useState, useEffect } from 'react';
import Logo from "../../img/logo.png";
import Perfil from "../../img/p2.png";
import './style.css';
import http from '../../services/api';
import { Link, useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from 'react-toastify';

import certidaoService from '../../services/CertidaoService';

function ListagemCertidao() {

    const navigate = useNavigate();
    const [certidao, setCertidao] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const formatarData = (data) => {
        const d = new Date(data);
        return d.toISOString().split('T')[0];
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await certidaoService.getAll();
                setCertidao(response.data.data);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    return (
        <div className='listagem'>
            <ToastContainer />
            <div className="container-fluid">
                <nav className="navbar">
                    <div className="logo">
                        <img src={Logo} alt="Certidões Católicas" />
                    </div>
                    <div className="user-actions">
                        <img src={Perfil} alt="Acesso Restrito" />
                    </div>
                </nav>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div>
                            <Link to="/Listagem" style={{ border: 'none' }}>
                                <button className='btn btn-success'>Voltar</button>
                            </Link>
                        </div>
                        <div className="mt-4">
                            <div className="card-header">
                                <h3>Solicitações</h3>
                            </div>
                            <div className="card-body">
                                <div className="style-tabela">
                                    <table border="0" cellPadding="0" cellSpacing="0" width="100%">
                                        <thead>
                                            <tr>
                                                <td className="top center"><strong>Id</strong></td>
                                                <td className="top center"><strong>Batizando</strong></td>
                                                <td className="top center"><strong>Data de Batismo</strong></td>
                                                <td className="top center"><strong>Data de Nascimento</strong></td>
                                                <td className="top center"><strong>Nome da Mãe</strong></td>
                                                <td className="top center"><strong>Nome do Pai</strong></td>
                                                <td className="top center"><strong>Email</strong></td>
                                                <td className="top center"><strong>Finalidade</strong></td>
                                                <td className="top center"><strong>Celebrante</strong></td>
                                                <td className="top center"><strong>Livro</strong></td>
                                                <td className="top center"><strong>Folha</strong></td>
                                                <td className="top center"><strong>Número</strong></td>
                                                <td className="top center"><strong>Paróquia</strong></td>
                                                <td className="top center" colSpan="3" width="1"><strong>Ações</strong></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <tr>
                                                    <td colSpan="14" align="center">Carregando...</td>
                                                </tr>
                                            ) : (
                                                certidao && certidao.length > 0 ? (
                                                    certidao.map((item) => (
                                                        <tr key={item.id}>
                                                            <td align="center">{item.id}</td>
                                                            <td align="center">{item.batizando}</td>
                                                            <td align="center">{formatarData(item.data_batismo)}</td>
                                                            <td align="center">{formatarData(item.data_nascimento)}</td>
                                                            <td align="center">{item.nome_mae}</td>
                                                            <td align="center">{item.nome_pai}</td>
                                                            <td align="center">{item.email}</td>
                                                            <td align="center">{item.finalidade}</td>
                                                            <td align="center">{item.celebrante}</td>
                                                            <td align="center">{item.livro}</td>
                                                            <td align="center">{item.folha}</td>
                                                            <td align="center">{item.numero}</td>
                                                            <td align="center">{item.paroquia_id}</td>
                                                            <td align="center">
                                                                {/* Aqui você pode adicionar ações como Editar ou Excluir */}
                                                                <Link to="/mostrar-pdf?pedidoId=1&certidaoId=1" style={{ border: 'none' }}>
                                                                    <button className="btn btn-primary">enviar por email</button>
                                                                </Link>
                                                            
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="14" align="center">Nenhuma certidão encontrada.</td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListagemCertidao;
