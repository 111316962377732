import React, { useState } from 'react';
import Logo from "../../img/logo.png"
import Perfil from "../../img/p2.png"
import './style.css'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'; 
import { Link } from 'react-router-dom';
import http from '../../services/api';

function FazerSolicitacao() {
  const [nome_completo, setnome_completo] = useState('');
  const [nome_mae, setnome_mae] = useState('');
  const [nome_pai, setnome_pai] = useState('');
  const [email, setemail] = useState('');
  const [data_nascimento, setdata_nascimento] = useState('');
  const [data_batismo, setdata_batismo] = useState('');
  const [finalidade, setfinalidade] = useState('');
  const [celebrante, setCelebrante] = useState('');
  const [batizando, setBatizando] = useState('');
  const [livro, setLivro] = useState('');
  const [folha, setFolha] = useState('');
  const [numero, setNumero] = useState('');
  const [paroco, setParoco] = useState('');
  const [paroquia, setParoquia] = useState(1);
  
  const [mensagem, setMensagem] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const response = await http.post('/api/v1/certidao/store', {
        
        nome_mae,
        nome_pai,
        email,
        data_nascimento,
        data_batismo,
        finalidade,
        celebrante,
        batizando,
        livro,
        folha,
        numero,
        paroco,
        paroquia_id: paroquia
      });

      if (response.status === 200) {
        setMensagem(response.data.message);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }

    } catch (error) {
      console.error('Erro:', error);
      toast.error('Erro ao fazer a solicitação');
    }
  };

  return (
    <div className='fazer-solicitacao'>
      <div className="container-fluid">
        <ToastContainer />

        <nav className="navbar">
          <div className="logo">
            <img src={Logo} alt="Certidões Católicas" />
          </div>
          <div className="user-actions">
            <Link to="/Login" style={{ border: 'none' }}>
              <img src={Perfil} alt="Acesso Restrito"  />
            </Link>
          </div>
        </nav>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card mt-4">
              <div className="card-header">
                <h3>Certidão de Batismo</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="nome_mae" className="form-label">Nome da Mãe</label>
                        <input
                          type="text"
                          className="form-control"
                          id="nome_mae"
                          value={nome_mae}
                          onChange={(e) => setnome_mae(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="nome_pai" className="form-label">Nome do Pai</label>
                        <input
                          type="text"
                          className="form-control"
                          id="nome_pai"
                          value={nome_pai}
                          onChange={(e) => setnome_pai(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="data_batismo" className="form-label">Data batismo</label>
                        <input
                          type="date"
                          className="form-control"
                          id="data_batismo"
                          value={data_batismo}
                          onChange={(e) => setdata_batismo(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="data_nascimento" className="form-label">Data nascimento</label>
                        <input
                          type="date"
                          className="form-control"
                          id="data_nascimento"
                          value={data_nascimento}
                          onChange={(e) => setdata_nascimento(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="livro" className="form-label">Livro</label>
                        <input
                          type="text"
                          className="form-control"
                          id="livro"
                          value={livro}
                          onChange={(e) => setLivro(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      
                      <div className="mb-3">
                        <label htmlFor="celebrante" className="form-label">Celebrante</label>
                        <input
                          type="text"
                          className="form-control"
                          id="celebrante"
                          value={celebrante}
                          onChange={(e) => setCelebrante(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="batizando" className="form-label">Batizando</label>
                        <input
                          type="text"
                          className="form-control"
                          id="batizando"
                          value={batizando}
                          onChange={(e) => setBatizando(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="folha" className="form-label">Folha</label>
                        <input
                          type="text"
                          className="form-control"
                          id="folha"
                          value={folha}
                          onChange={(e) => setFolha(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="numero" className="form-label">Número</label>
                        <input
                          type="text"
                          className="form-control"
                          id="numero"
                          value={numero}
                          onChange={(e) => setNumero(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="paroco" className="form-label">Paroco</label>
                        <input
                          type="text"
                          className="form-control"
                          id="paroco"
                          value={paroco}
                          onChange={(e) => setParoco(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  
                  <div className="fazer-solicitacao-container">
                    <button type="submit" className="btn btn-primary">Solicitar</button>
                    <Link to="/Listagem" style={{ border: 'none' }}>
              <button className='btn btn-success'> Voltar  </button> 
            </Link>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FazerSolicitacao;
